.AllFeedsView {
  transition: 0.3s;
}
.AllFeeds__Button {
  width: fit-content;
  border: 2px solid #ffffff;
  background: transparent;
  margin: 20px 0px;
  padding: 8px 12px;
  font-family: 'Poppins Light';
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}
.AllFeeds__ButtonDark {
  width: fit-content;
  border: 2px solid #262626;
  background: transparent;
  margin: 20px 0px;
  padding: 8px 12px;
  font-family: 'Poppins Light';
  color: #262626;
  font-size: 16px;
  cursor: pointer;
}
.Loading {
  height: 40vh;
  margin-top: 40px;
  transition: 0.3s;
}
