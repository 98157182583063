.Footer {
  width: 100%;
  height: 77px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1c1c1c;
}
.ModeSwitcher {
  display: flex;
  width: fit-content;
  align-items: center;
  margin-right: 28px;
}
.ModeSwitcherText {
  color: #777777;
}
.FooterText {
  color: #777777;
  margin-left: 28px;
}
/* Slider Overrides */
.css-17kdmr0-MuiButtonBase-root-MuiSwitch-switchBase {
  color: #fbc51b !important;
}

/* Media Queries */
@media (max-width: 600px) {
  .Footer {
    justify-content: center;
  }
  .FooterText {
    margin-left: 0;
  }
}
