.Table {
  display: flex;
  flex-direction: column;
  user-select: none;
  
}
.TH__Header {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: left;
}
.TH__Header > th {
  flex: 0.2;
  display: flex;
  align-items: center;
  margin-top: 20px;
  flex: 0.2;
  min-width: 100px; /* Replace with the minimum width you want */
  overflow: hidden; /* This will cut off the content that overflows the box */
  padding-right: 10px; /* Add space to the right inside the element */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}
.TH__HeaderSpecial {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}
.TH__HeaderDiv {
  flex: 0.2;
  display: flex;
  align-items: center;
}
.TH__Header > th > h1 {
  font-size: 12px;
  font-weight: 300;
  padding-right: 8px;
}
.TH__HeaderDiv > h1 {
  font-size: 12px;
  font-weight: 300;
  padding-right: 8px;
}
.TH__HeaderDiv > svg {
  width: 14px;
  height: 14px;
}
.FilterIcon {
  cursor: pointer;
}
.FilterIcon > path {
  fill: #ffffff;
  transition: 0.3s;
}
.FilterIconDark {
  cursor: pointer;
}
.FilterIconDark > path {
  fill: #262626;
  transition: 0.3s;
}
.TH__Header > th > svg {
  width: 14px;
  height: 14px;
}
.TableFilterDropdown {
  display: none;
  flex-direction: column;
  padding: 8px;
  width: fit-content;
  height: 180px;
  background: #fafafa;
  border-bottom: 2px solid #fbc51b;
  color: #262626;
  z-index: 10;
  position: absolute;
  margin-top: 20px;
  margin-left: 3rem;
}
.TableFilterDropdownDark {
  display: none;
  flex-direction: column;
  padding: 8px;
  width: fit-content;
  height: 180px;
  background: #262626;
  border-bottom: 2px solid #fbc51b;
  color: #fafafa;
  z-index: 10;
  position: absolute;
  margin-top: 20px;
  margin-left: 3rem;
}
.TableFilterDropdown > h3 {
  margin-bottom: 10px;
}
.TableFilterDropdownDark > h3 {
  margin-bottom: 10px;
}
.DropdownResults {
  overflow: auto;
  height: 100%;
}
.DropdownDataRow {
  display: flex;
  align-items: center;
  width: 160px;
  height: 25px;
  justify-content: space-between;
  border-bottom: 1px solid #262626;
  cursor: pointer;
}
.DropdownDataRowDark {
  display: flex;
  align-items: center;
  width: 160px;
  height: 25px;
  justify-content: space-between;
  border-bottom: 1px solid #fafafa;
  cursor: pointer;
}
.DropdownCheckDark > path {
  fill: #fafafa;
}
.DropdownButtons {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-top: 8px;
}
.DropdownButtonsDark {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-top: 8px;
}
.DropdownButtons > button {
  flex: 0.45;
  height: 1.5rem;
  font-family: 'Poppins Light';
  cursor: pointer;
}
.DropdownButtonsDark > button {
  flex: 0.45;
  height: 1.5rem;
  font-family: 'Poppins Light';
  cursor: pointer;
}
.DropdownApply {
  background-color: #fbc51b;
  border: 1px solid #fbc51b;
}
.DropdownClear {
  background-color: transparent;
  border: 1px solid #fbc51b;
}
.DropdownClearDark {
  background-color: transparent;
  border: 1px solid #fbc51b;
  color: #fafafa;
}
.TableBody {
  width: 100%;
  justify-content: stretch;
  display: flex;
  flex-direction: row;
  text-align: left;
  padding: 14px 0px 10px 0px;
  box-sizing: border-box;
  border-bottom: 1px solid #ffffff;
  transition: 0.2s;
  animation: fadeIn 1.5s forwards;

}
.TableBody:hover {
  background-color: #363636;
  cursor: pointer;
}
.TableBodyDark {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: left;
  padding: 14px 0px 10px 0px;
  border-bottom: 1px solid #262626;
  transition: 0.2s;
  animation: fadeIn 1.5s forwards;
  box-sizing: border-box; /* This line ensures padding is included in the element's dimensions */
}
.TableBodyDark:hover {
  background-color: #ececec;
  cursor: pointer;
}
.TableBodyNoMatches {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #fbc51b;
}
.TB__Symbols {
  flex: 0.2;
  min-width: 100px; /* Replace with the minimum width you want */
  overflow: hidden; /* This will cut off the content that overflows the box */
  padding-right: 0px; /* Add space to the right inside the element */
}
.TB__Value {
  flex: 0.2;
  min-width: 100px; /* Replace with the minimum width you want */
  overflow: hidden; /* This will cut off the content that overflows the box */
  padding-left: 0px; /* Add space to the right inside the element */

}
.TB__Balance {
  flex: 0.2;
  min-width: 100px; /* Replace with the minimum width you want */
  overflow: hidden; /* This will cut off the content that overflows the box */
}
.TB__Chain {
  flex: 0.2;
  min-width: 100px; /* Replace with the minimum width you want */
  overflow: hidden; /* This will cut off the content that overflows the box */
}
.TB__Reporter {
  flex: 0.2;
  display: flex;
  min-width: 100px; /* Replace with the minimum width you want */
  overflow: hidden; /* This will cut off the content that overflows the box */
}
.Table__Jazzicon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.TB__DateTime {
  flex: 0.2;
  min-width: 170px; /* Replace with the minimum width you want */

}

/* TABLE GLOBAL CLASSES */
.display {
  display: flex !important;
}

/* KeyFrames */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.GreenLink {
  color: #fbc51b;
}

@media (max-width: 768px) and (orientation: portrait) {
  .TableBody, .TableBodyDark {
    padding: 10px 600px 10px 0px; /* Adjusted for portrait mode */
    margin-left: -0px;
  }

  /* Add any other adjustments specific to portrait mode here */
}

.input-text {
  width: 100px; /* Example width for text inputs */
  height: 30px; /* Example height for text inputs */
  /* Add any other styling properties you need */
}

.input-date {
  width: 120px; /* Example width for date inputs */
  height: 35px; /* Example height for date inputs */
  /* Add any other styling properties you need */
}