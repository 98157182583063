@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;700&display=swap);
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins SemiBold'),
    url(/static/media/Poppins-SemiBold.ac8d04b6.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins Light';
  font-style: normal;
  font-weight: 200;
  src: local('Poppins Light'),
    url(/static/media/Poppins-Light.7641a0f7.ttf) format('truetype');
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Poppins Light';
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* Joshua Samaniego Updates */
html {
  width: 100vw;
  height: 100vh;
}
body {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
}

.Nav {
    width: 92%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.TellorLogo {
    width: 140px;
}

/* Media Queries */
@media (max-width: 600px) {
    .Nav {
        flex-direction: column;
        justify-content: space-around;
        padding: 20px 0 54px;
    }
}
@media (max-width: 500px) {
    .Nav {
        justify-content: space-around;
    }
}
.Footer {
  width: 100%;
  height: 77px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1c1c1c;
}
.ModeSwitcher {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  margin-right: 28px;
}
.ModeSwitcherText {
  color: #777777;
}
.FooterText {
  color: #777777;
  margin-left: 28px;
}
/* Slider Overrides */
.css-17kdmr0-MuiButtonBase-root-MuiSwitch-switchBase {
  color: #fbc51b !important;
}

/* Media Queries */
@media (max-width: 600px) {
  .Footer {
    justify-content: center;
  }
  .FooterText {
    margin-left: 0;
  }
}

.HeroInnerContainer {
  margin: 0px 100px;
  height: -moz-fit-content;
  height: fit-content;
}
.HeroHeader {
  font-family: 'Poppins';
  font-size: 51px;
  line-height: 60px;
  margin-bottom: 20px;
}
.HeroMainText {
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
  margin-bottom: 30px;
  padding-right: 200px;
}
.HeroNavLinks {
  display: flex;
  line-height: 28px;
  transition: 0.3s;
}
.HeroNavLinksLight {
  display: flex;
  line-height: 28px;
  transition: 0.3s;
}
.HeroNavLinks > a {
  color: #ffffff;
  margin-right: 35px;
  transition: 0.3s;
}
.HeroNavLinksLight > a {
  color: #262626;
  margin-right: 35px;
  transition: 0.3s;
}
.ActiveLink {
  padding-bottom: 3px;
  text-decoration: none;
  border-bottom: 2px solid #fbc51b;
}
.InactiveLink {
  padding-bottom: 3px;
  text-decoration: none;
  border-bottom: 2px solid #4b4b4b;
  opacity: 80%;
}
.HeroSection {
  margin-top: 10px;
  height: -moz-fit-content;
  height: fit-content;
}

.AllFeedsView {
  transition: 0.3s;
}
.AllFeeds__Button {
  width: -moz-fit-content;
  width: fit-content;
  border: 2px solid #ffffff;
  background: transparent;
  margin: 20px 0px;
  padding: 8px 12px;
  font-family: 'Poppins Light';
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}
.AllFeeds__ButtonDark {
  width: -moz-fit-content;
  width: fit-content;
  border: 2px solid #262626;
  background: transparent;
  margin: 20px 0px;
  padding: 8px 12px;
  font-family: 'Poppins Light';
  color: #262626;
  font-size: 16px;
  cursor: pointer;
}
.Loading {
  height: 40vh;
  margin-top: 40px;
  transition: 0.3s;
}

.Table {
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
          user-select: none;
  
}
.TH__Header {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: left;
}
.TH__Header > th {
  flex: 0.2 1;
  display: flex;
  align-items: center;
  margin-top: 20px;
  flex: 0.2 1;
  min-width: 100px; /* Replace with the minimum width you want */
  overflow: hidden; /* This will cut off the content that overflows the box */
  padding-right: 10px; /* Add space to the right inside the element */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}
.TH__HeaderSpecial {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}
.TH__HeaderDiv {
  flex: 0.2 1;
  display: flex;
  align-items: center;
}
.TH__Header > th > h1 {
  font-size: 12px;
  font-weight: 300;
  padding-right: 8px;
}
.TH__HeaderDiv > h1 {
  font-size: 12px;
  font-weight: 300;
  padding-right: 8px;
}
.TH__HeaderDiv > svg {
  width: 14px;
  height: 14px;
}
.FilterIcon {
  cursor: pointer;
}
.FilterIcon > path {
  fill: #ffffff;
  transition: 0.3s;
}
.FilterIconDark {
  cursor: pointer;
}
.FilterIconDark > path {
  fill: #262626;
  transition: 0.3s;
}
.TH__Header > th > svg {
  width: 14px;
  height: 14px;
}
.TableFilterDropdown {
  display: none;
  flex-direction: column;
  padding: 8px;
  width: -moz-fit-content;
  width: fit-content;
  height: 180px;
  background: #fafafa;
  border-bottom: 2px solid #fbc51b;
  color: #262626;
  z-index: 10;
  position: absolute;
  margin-top: 20px;
  margin-left: 3rem;
}
.TableFilterDropdownDark {
  display: none;
  flex-direction: column;
  padding: 8px;
  width: -moz-fit-content;
  width: fit-content;
  height: 180px;
  background: #262626;
  border-bottom: 2px solid #fbc51b;
  color: #fafafa;
  z-index: 10;
  position: absolute;
  margin-top: 20px;
  margin-left: 3rem;
}
.TableFilterDropdown > h3 {
  margin-bottom: 10px;
}
.TableFilterDropdownDark > h3 {
  margin-bottom: 10px;
}
.DropdownResults {
  overflow: auto;
  height: 100%;
}
.DropdownDataRow {
  display: flex;
  align-items: center;
  width: 160px;
  height: 25px;
  justify-content: space-between;
  border-bottom: 1px solid #262626;
  cursor: pointer;
}
.DropdownDataRowDark {
  display: flex;
  align-items: center;
  width: 160px;
  height: 25px;
  justify-content: space-between;
  border-bottom: 1px solid #fafafa;
  cursor: pointer;
}
.DropdownCheckDark > path {
  fill: #fafafa;
}
.DropdownButtons {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-top: 8px;
}
.DropdownButtonsDark {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-top: 8px;
}
.DropdownButtons > button {
  flex: 0.45 1;
  height: 1.5rem;
  font-family: 'Poppins Light';
  cursor: pointer;
}
.DropdownButtonsDark > button {
  flex: 0.45 1;
  height: 1.5rem;
  font-family: 'Poppins Light';
  cursor: pointer;
}
.DropdownApply {
  background-color: #fbc51b;
  border: 1px solid #fbc51b;
}
.DropdownClear {
  background-color: transparent;
  border: 1px solid #fbc51b;
}
.DropdownClearDark {
  background-color: transparent;
  border: 1px solid #fbc51b;
  color: #fafafa;
}
.TableBody {
  width: 100%;
  justify-content: stretch;
  display: flex;
  flex-direction: row;
  text-align: left;
  padding: 14px 0px 10px 0px;
  box-sizing: border-box;
  border-bottom: 1px solid #ffffff;
  transition: 0.2s;
  animation: fadeIn 1.5s forwards;

}
.TableBody:hover {
  background-color: #363636;
  cursor: pointer;
}
.TableBodyDark {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: left;
  padding: 14px 0px 10px 0px;
  border-bottom: 1px solid #262626;
  transition: 0.2s;
  animation: fadeIn 1.5s forwards;
  box-sizing: border-box; /* This line ensures padding is included in the element's dimensions */
}
.TableBodyDark:hover {
  background-color: #ececec;
  cursor: pointer;
}
.TableBodyNoMatches {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #fbc51b;
}
.TB__Symbols {
  flex: 0.2 1;
  min-width: 100px; /* Replace with the minimum width you want */
  overflow: hidden; /* This will cut off the content that overflows the box */
  padding-right: 0px; /* Add space to the right inside the element */
}
.TB__Value {
  flex: 0.2 1;
  min-width: 100px; /* Replace with the minimum width you want */
  overflow: hidden; /* This will cut off the content that overflows the box */
  padding-left: 0px; /* Add space to the right inside the element */

}
.TB__Balance {
  flex: 0.2 1;
  min-width: 100px; /* Replace with the minimum width you want */
  overflow: hidden; /* This will cut off the content that overflows the box */
}
.TB__Chain {
  flex: 0.2 1;
  min-width: 100px; /* Replace with the minimum width you want */
  overflow: hidden; /* This will cut off the content that overflows the box */
}
.TB__Reporter {
  flex: 0.2 1;
  display: flex;
  min-width: 100px; /* Replace with the minimum width you want */
  overflow: hidden; /* This will cut off the content that overflows the box */
}
.Table__Jazzicon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.TB__DateTime {
  flex: 0.2 1;
  min-width: 170px; /* Replace with the minimum width you want */

}

/* TABLE GLOBAL CLASSES */
.display {
  display: flex !important;
}

/* KeyFrames */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.GreenLink {
  color: #fbc51b;
}

@media (max-width: 768px) and (orientation: portrait) {
  .TableBody, .TableBodyDark {
    padding: 10px 600px 10px 0px; /* Adjusted for portrait mode */
    margin-left: -0px;
  }

  /* Add any other adjustments specific to portrait mode here */
}

.input-text {
  width: 100px; /* Example width for text inputs */
  height: 30px; /* Example height for text inputs */
  /* Add any other styling properties you need */
}

.input-date {
  width: 120px; /* Example width for date inputs */
  height: 35px; /* Example height for date inputs */
  /* Add any other styling properties you need */
}
.App {
  width: 100%;
  max-height: 100%;
  min-height: 100vh;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #262626;
  color: #ffffff;
  font-family: 'Poppins Light';
  transition: 0.3s;
}
.HeroContainer {
  width: 92%;
  justify-content: stretch;
  min-height: calc(80vh - 100px);
  display: flex;
  flex-direction: column;
  background-color: #262626;
  overflow-x: auto; /* Allow horizontal scrolling */
  margin-bottom: 100px;
  transition: 0.3s;
}

/* LIGHT MODE STYLES */
.AppLight {
  width: 100%;
  max-height: 100%;
  min-height: 100vh;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  color: #262626;
  font-family: 'Poppins Light';
  transition: 0.3s;
}
.HeroContainerLight {
  width: 92%;
  min-height: calc(80vh - 100px);
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  overflow-x: auto; /* Allow horizontal scrolling */

  margin-bottom: 100px;
  transition: 0.3s;
}
